<template>
  <div>
    <Header></Header>
    <!-- 大图 -->
    <div>
      <div>彼思科技资讯中心</div>
      <div>聚焦零售圈资讯</div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "new_index",
  data() {
    return {};
  },
  components: { Header, Footer },
};
</script>

<style>
</style>